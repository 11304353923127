window.showImage = function (article, id, location, permitted) {
  background = document.createElement("div");
  background.classList.add("item__background");
  background.id = "item_background";
  document.body.appendChild(background);

  image = document.getElementById(`image_${id}`).cloneNode();
  image.classList.add("gallery__item_selected");
  image.setAttribute("onClick", "");
  background.appendChild(image);

  closeBackground = document.createElement("div");
  closeBackground.classList.add("close__background");
  closeBackground.setAttribute("onClick", `closeImage("${background.id}")`);
  background.appendChild(closeBackground);

  close = document.createElement("img");
  close.setAttribute("src", "/assets/white_cross.svg");
  close.classList.add("item__close");
  closeBackground.appendChild(close);

  if (permitted) {
    remove = document.createElement("a");
    remove.classList.add("button", "button_confirm", "item__remove");
    remove.setAttribute("href", `/articles/remove_image/${article}/${id}/${location}`);
    remove.innerHTML = "Entfernen";
    background.appendChild(remove);
  }

  document.getElementById("main").setAttribute("style", "display: none;");
  document.getElementById("nav").setAttribute("style", "display: none;");
}

window.closeImage = function (id) {
  document.getElementById(id).remove();

  document.getElementById("main").setAttribute("style", "display: block;");
  document.getElementById("nav").setAttribute("style", "display: flex;");
}